import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ArrowBarLeft } from "react-bootstrap-icons";
import Purchase from "./Purchase2";
import { ApiUrl } from "../../Network/Urls";
import MpesaPay from "./MpesaPay/MpesaPay";
import { CountryCities } from "../../Utils/CountryCities";
import { CountryList } from "../../Utils/CountryList";
import swal from "sweetalert";
import PaystackPay from "./PaystackPay/PaystackPay";

const WebPay2 = () => {
	const Navigate = useNavigate();
	const inst = useSelector((state) => state.inst.activeInst);
	const subs = useSelector((state) => state.sub.subs);
	const [Agent, setAgent] = useState({ cost: 60 });
	const [Loading, setLoading] = useState(false);
	const [Rate, setRate] = useState(0);
	const [KenyanRate, setKenyanRate] = useState(0);
	const [Currency, setCurrency] = useState("USD");
	const [Fetching, setFetching] = useState(false);

	useEffect(() => {
		GetAgent();
		GetExchangeRate();
	}, []);

	const GetAgent = () => {
		setLoading(true);
		fetch(`${ApiUrl}/agent/inst`, {
			method: "POST",
			headers: {
				"content-type": "application/json",
				authorization: `bearer `,
			},
			body: JSON.stringify({
				softwareId: parseInt(
					parseInt(`${inst.linker}`.split("").reverse().join("")) / 1000
				),
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				setLoading(false);
				if (res.agent) {
					setAgent(res.agent);
				}
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const GetExchangeRate = async () => {
		setFetching(true);
		await fetch(`${ApiUrl}/sub/exchange`, {
			method: "POST",
			headers: {
				"content-type": "application/json",
				authorization: `bearer `,
			},
			body: JSON.stringify({}),
		})
			.then((res) => res.json())
			.then((res) => {
				setFetching(false);
				if (res.status === 200) {
					if (Intl) {
						let TzArr = Intl.DateTimeFormat()
							.resolvedOptions()
							.timeZone.split("/");
						let userCity = TzArr[TzArr.length - 1];
						let userCountry = CountryCities[userCity];
						let userCurrency = CountryList.find(
							(country) =>
								country.countryName.toLowerCase() === userCountry.toLowerCase()
						);

						setCurrency(userCurrency.currencyCode || Currency);

						setKenyanRate(res.data["KES"] || Rate);

						setRate(res.data[(userCurrency || {}).currencyCode] || Rate);
					}
				} else {
					swal({
						title: "Failed to connect",
						icon: "warning",
						timer: 3000,
					});
				}
			})
			.catch(() => {
				setFetching(false);
				swal({
					title: "Failed to connect",
					icon: "warning",
					timer: 3000,
				});
			});
	};

	return (
		<div>
			{" "}
			{Loading || Fetching ? (
				<p className="text-center h5">
					{" "}
					<span className="spinner-border text-primary"></span>
				</p>
			) : (
				<>
					{" "}
					<div
						className=" row d-flex justify-content-center"
						style={{ marginTop: "40px" }}
					>
						<div className="col-md-6 ">
							<p className="text-center text-capitalize h6 text-decoration-underline">
								<strong>Get {inst.name} Activation Code at :</strong>
							</p>
							<ul>
								{/*	<li>
									<div className="d-flex justify-content-between align-content-center">
										<span>
											{" "}
											<strong>
												One Time Pay <br />
												Forever Access :
											</strong>{" "}
											{parseInt(
												(Agent.cost > 71.9 ? Agent.cost : 60) *
													10 *
													(Rate === 0 ? 1 : Rate)
											) + (Rate === 0 ? Rate : 1)}{" "}
											{Currency} Only{" "}
										</span>
										{Currency === "KES" && Rate !== 0 ? (
											<MpesaPay
												amount={
													parseInt(
														(Agent.cost > 71.9 ? Agent.cost : 60) *
															10 *
															(Rate === 0 ? 1 : Rate)
													) + (Rate === 0 ? Rate : 1)
												}
												type="lifetime"
												softwareId={parseInt(
													parseInt(
														`${inst.linker}`.split("").reverse().join("")
													) / 1000
												)}
											></MpesaPay>
										) : (
											<PaystackPay
												amountUSD={(Agent.cost > 71.9 ? Agent.cost : 60) * 10}
												kenyanRate={KenyanRate}
												localRate={Rate}
												localCurrency={Currency}
												type="lifetime"
												softwareId={parseInt(
													parseInt(
														`${inst.linker}`.split("").reverse().join("")
													) / 1000
												)}
											></PaystackPay>
										)}
									</div>
								</li>{" "}*/}
								<li>
									<div className="d-flex justify-content-between">
										<span>
											{" "}
											<strong>Annual :</strong>{" "}
											{parseInt(
												(Agent.cost > 71.9 ? Agent.cost : 60) *
													(Rate === 0 ? 1 : Rate)
											) + (Rate === 0 ? Rate : 1)}{" "}
											{Currency} Only{" "}
										</span>
										{Currency === "KES" && Rate !== 0 ? (
											<MpesaPay
												amount={
													parseInt(
														(Agent.cost > 71.9 ? Agent.cost : 60) *
															(Rate === 0 ? 1 : Rate)
													) + (Rate === 0 ? Rate : 1)
												}
												type="annual"
												softwareId={parseInt(
													parseInt(
														`${inst.linker}`.split("").reverse().join("")
													) / 1000
												)}
											></MpesaPay>
										) : (
											<PaystackPay
												amountUSD={Agent.cost > 71.9 ? Agent.cost : 60}
												kenyanRate={KenyanRate}
												localRate={Rate}
												localCurrency={Currency}
												type="annual"
												softwareId={parseInt(
													parseInt(
														`${inst.linker}`.split("").reverse().join("")
													) / 1000
												)}
											></PaystackPay>
										)}
									</div>
								</li>{" "}
								<li className="mt-1">
									{" "}
									<div className="d-flex justify-content-between">
										<span>
											{" "}
											<strong>BiAnnual :</strong>{" "}
											{parseInt(
												((Agent.cost > 71.9 ? Agent.cost : 60) / 2) *
													(Rate === 0 ? 1 : Rate)
											) + (Rate === 0 ? Rate : 1)}{" "}
											{Currency} Only{" "}
										</span>
										{Currency === "KES" && Rate !== 0 ? (
											<MpesaPay
												amount={
													parseInt(
														((Agent.cost > 71.9 ? Agent.cost : 60) / 2) *
															(Rate === 0 ? 1 : Rate)
													) + (Rate === 0 ? Rate : 1)
												}
												type="biannual"
												softwareId={parseInt(
													parseInt(
														`${inst.linker}`.split("").reverse().join("")
													) / 1000
												)}
											></MpesaPay>
										) : (
											<PaystackPay
												amountUSD={(Agent.cost > 71.9 ? Agent.cost : 60) / 2}
												kenyanRate={KenyanRate}
												localRate={Rate}
												localCurrency={Currency}
												type="biannual"
												softwareId={parseInt(
													parseInt(
														`${inst.linker}`.split("").reverse().join("")
													) / 1000
												)}
											></PaystackPay>
										)}
									</div>
								</li>{" "}
								<li className="mt-1">
									<div className="d-flex justify-content-between">
										<span>
											{" "}
											<strong>Quarterly :</strong>{" "}
											{parseInt(
												((Agent.cost > 71.9 ? Agent.cost : 60) / 4) *
													(Rate === 0 ? 1 : Rate)
											) + (Rate === 0 ? Rate : 1)}{" "}
											{Currency} Only
										</span>
										{Currency === "KES" && Rate !== 0 ? (
											<MpesaPay
												amount={
													parseInt(
														((Agent.cost > 71.9 ? Agent.cost : 60) / 4) *
															(Rate === 0 ? 1 : Rate)
													) + (Rate === 0 ? Rate : 1)
												}
												type="quarterly"
												softwareId={parseInt(
													parseInt(
														`${inst.linker}`.split("").reverse().join("")
													) / 1000
												)}
											></MpesaPay>
										) : (
											<PaystackPay
												amountUSD={(Agent.cost > 71.9 ? Agent.cost : 60) / 4}
												kenyanRate={KenyanRate}
												localRate={Rate}
												localCurrency={Currency}
												type="quarterly"
												softwareId={parseInt(
													parseInt(
														`${inst.linker}`.split("").reverse().join("")
													) / 1000
												)}
											></PaystackPay>
										)}
									</div>
								</li>{" "}
								<li className="mt-1">
									<div className="d-flex justify-content-between">
										<span>
											{" "}
											<strong>Monthly :</strong>{" "}
											{parseInt(
												((Agent.cost > 71.9 ? Agent.cost : 60) / 12) *
													(Rate === 0 ? 1 : Rate)
											) + (Rate === 0 ? Rate : 1)}{" "}
											{Currency} Only
										</span>
										{Currency === "KES" && Rate !== 0 ? (
											<MpesaPay
												amount={
													parseInt(
														((Agent.cost > 71.9 ? Agent.cost : 60) / 12) *
															(Rate === 0 ? 1 : Rate)
													) + (Rate === 0 ? Rate : 1)
												}
												type="monthly"
												softwareId={parseInt(
													parseInt(
														`${inst.linker}`.split("").reverse().join("")
													) / 1000
												)}
											></MpesaPay>
										) : (
											<PaystackPay
												amountUSD={(Agent.cost > 71.9 ? Agent.cost : 60) / 12}
												kenyanRate={KenyanRate}
												localRate={Rate}
												localCurrency={Currency}
												type="monthly"
												softwareId={parseInt(
													parseInt(
														`${inst.linker}`.split("").reverse().join("")
													) / 1000
												)}
											></PaystackPay>
										)}
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div className=" row d-flex justify-content-center">
						<div className="col-md-6">
							<p className="h6 text-decoration-underline text-capitalize p-1">
								<strong>In Case You need help :</strong>
							</p>
							<ul>
								<li>
									<strong>Email : </strong>
									<em>contact.techsystems@gmail.com</em>
								</li>
								<li>
									<strong>Whatsapp : </strong>
									<em>+254 703535116 / +254 745301833</em>
								</li>
								<li>
									<strong>Facebook : </strong>
									<em>www.facebook.com/techsystems.world</em>
								</li>
							</ul>
						</div>
					</div>
					<div className=" row d-flex justify-content-center">
						<div className="col-md-6 d-flex justify-content-around">
							<button
								className="btn btn-sm btn-outline-secondary"
								onClick={() => Navigate(-1)}
							>
								<ArrowBarLeft></ArrowBarLeft> Go Back
							</button>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default WebPay2;
